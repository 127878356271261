export default function MainBanner() {
  return (
    <div className='sticky top-0 isolate flex items-center gap-x-6 bg-[#247aff] px-6 py-2.5 sm:px-3.5 sm:before:flex-1 z-[1000]'>
      <p className='text-sm leading-6 text-white'>
        <a
          href='https://app.uniswap.org/explore/pools/ethereum/0x1F9B4A5DdD586F8471Cda36262c52E5872a7D257'
          target='_blank'
          rel="noreferrer"
        >
          <strong className='font-semibold'>Sharpe Token $SAI is now live!</strong>
          <svg viewBox='0 0 2 2' aria-hidden='true' className='mx-2 inline h-0.5 w-0.5 fill-current'>
            <circle r={1} cx={1} cy={1} />
          </svg>
          Buy now on Uniswap&nbsp;<span aria-hidden='true'>&rarr;</span>
        </a>
      </p>
      <div className='flex flex-1 justify-end'></div>
    </div>
  )
}
