import React from "react";
import { Icon } from "@iconify/react";

export default function SwitchButtons({ mode }) {
  return (
    <div className="bg-[#FFFFFF] dark:bg-[#09090B] mt-[10px]  text-[14px] text-[#000] dark:text-[#fff] leading-none border-2 border-[#FFFFFF] dark:border-[#0a0a0c] rounded-t-[16px] inline-flex p-[6px] h-[55px] w-[416px]">
      <button
        className="flex-1 inline-flex items-center justify-center transition-colors gap-[8px] duration-300 ease-in focus:outline-none rounded-r-full px-4 py-0"
        id="grid"
        onClick={() => (window.location.href = "https://bridge.sharpe.ai")}
      >
        <Icon
          icon="ic:baseline-swap-horiz"
          width="20"
          height="20"
          style={{ color: mode === "light" ? "#000000" : "#EFEFEF" }}
        />
        <span>Cross-Chain Swap</span>
      </button>
      <button
        className="flex-1 inline-flex items-center justify-center transition-colors gap-1 bg-[#e9e8e8] dark:bg-[#98ECFF] border border-[#dadada] dark:border-[#1E1E1E] duration-100 ease-in focus:outline-none text-black dark:text-[#000] rounded-[12px] px-6 py-0 active"
        id="list"
      >
        <Icon
          icon="ic:baseline-local-gas-station"
          width="20"
          height="20"
          style={{ color: mode === "light" ? "#000000" : "#000" }}
        />
        <span>Get Gas</span>
      </button>
    </div>
  );
}